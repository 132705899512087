import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { PageLoader } from 'components/PageLoader'
import { LayoutApp } from 'components/layout/app/LayoutApp'
import { LayoutAuth } from 'components/layout/auth/LayoutAuth'
import { SeoTitle } from 'components/seo/SeoTitle'
import { LINKS } from 'constants/links'
import { FALLBACK_LOCALE } from 'constants/locale'
import { GetStaticProps } from 'next'
import { useSession } from 'next-auth/react'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { usePageTranslation } from 'utils/intl'

function NotFoundPage() {
  const session = useSession()
  const t = usePageTranslation(['common', 'errorPage'])

  if (session.status === 'loading') {
    return <PageLoader />
  }

  return (
    <>
      <SeoTitle title={t('errorPage:notFound.title')} />

      {session.status === 'authenticated' ? (
        <LayoutApp>
          <Container maxWidth="md">
            <Stack
              paddingY={12}
              gap={2}
              alignItems="center"
              justifyContent="center"
              textAlign="center"
            >
              <Typography variant="h2">
                {t('errorPage:notFound.title')}
              </Typography>

              <Typography>{t('errorPage:notFound.subtitle')}</Typography>

              <Button variant="contained" size="large" href={LINKS.app}>
                {t('common:backToHomepage')}
              </Button>
            </Stack>
          </Container>
        </LayoutApp>
      ) : (
        <LayoutAuth
          title={t('errorPage:notFound.title')}
          subtitle={t('errorPage:notFound.subtitle')}
        >
          <Box textAlign="center">
            <Button variant="contained" size="large" href={LINKS.home}>
              {t('common:backToHomepage')}
            </Button>
          </Box>
        </LayoutAuth>
      )}
    </>
  )
}

export const getStaticProps: GetStaticProps = async context => {
  return {
    props: {
      ...(await serverSideTranslations(context.locale ?? FALLBACK_LOCALE, [
        'common',
        'app',
        'errorPage'
      ]))
    }
  }
}

export default NotFoundPage
